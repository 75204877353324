import {
  Button,
  Form,
  Select,
  Divider,
  Spin,
  message,
  Input
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'; 
import { getPreScreenerQuestions, submitPreScreenerAnswers } from "../redux/slice/surveySlice";

const Prescreener = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); 
  const [submitted, setSubmitted] = useState(false); 
  const [isRedirecting, setIsRedirecting] = useState(false); 
  const assignedSurveys = useSelector(({ assignedSurveys }) => assignedSurveys);
  const location = useLocation();
  const getQuotaIdFromUrl = () => {
    const params = new URLSearchParams(location.search); 
    return params.get('quota_id'); 
  };

  useEffect(() => {
    const quotaId = getQuotaIdFromUrl(); 
    if (quotaId) {
      dispatch(getPreScreenerQuestions(quotaId)); 
    } else {
      message.error('Quota ID not found in the URL.');
    }
  }, [dispatch, location]);

  const onFinish = (data) => {
    const answers = {};
    assignedSurveys.preScreenerQuestions.questions.forEach((question) => {
      const answer = data[question.id];
      
    
      if (question.answer_type === 'input') {
        answers[question.id] = answer; 
      } else {
        answers[question.id] = Array.isArray(answer) ? answer : [answer]; 
      }
    });
  
    setLoading(true);
    setSubmitted(true);
   //  console.log("submitPreScreenerAnswers",answers) 
     dispatch(submitPreScreenerAnswers(answers))
  
      .then(() => {
        const startLink = assignedSurveys.preScreenerQuestions?.start_link;
        const ipDetail = JSON.parse(localStorage.getItem("ip_details") || "{}");
        if (startLink) {
          setIsRedirecting(true);  
          
          setTimeout(() => {
            window.open(
              `${startLink}&ip=${ipDetail.ip}&country=${ipDetail.country}`,
              "_blank",
              "noopener,noreferrer"
            );
            setIsRedirecting(false); 
          }, 4000);  
        }
        
        setLoading(false); 
      })
      .catch(() => {
        setLoading(false);  
        message.error('Failed to submit survey. Please try again.');
      });
  };
  
  const content = <div style={{minWidth:300}} />;
  const onSearch = (value) => {
    console.log('search:', value);
  };

  if (loading || isRedirecting) {
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}>
        <Spin 
          tip={isRedirecting ? "Redirecting to the survey page..." : "Submitting your responses, please wait..."}
          size="large"
          style={{ fontSize: "16px" }} 
        >
        {content}
        </Spin>
      </div>
    );
  }

  if (assignedSurveys.fetching) {
    return <Spin tip="Loading..." />;
  }

  if (assignedSurveys.error) {
    return <div>Error loading data: {assignedSurveys.error}</div>;
  }

  return (
    <div className="container">
      <div className="row " style={{ display: "flex", justifyContent: "center" }}>
        <div className="col-md-8">
          <div className="prescreener-page" style={{ padding: "20px" }}>
      <div className="header" style={{ display: "flex", alignItems: "center" }}>
        <h3>{assignedSurveys.preScreenerQuestions?.name || 'Prescreener'}</h3>
      </div>
      {assignedSurveys.preScreenerQuestions?.questions?.length ? (
        <Form
        layout="vertical"
        name="prescreenerDetails"
        style={{ margin: "20px 0" }}
        onFinish={onFinish}
        form={form}
      >
        {assignedSurveys.preScreenerQuestions.questions.map((question, index) => (
          <div key={question.id}>
            <Form.Item
              name={question.id}
              label={<h5>{index + 1}. {question.display_name}</h5>}
              rules={[{ required: true, message: 'This question is required.' }]}
            >
              {question.answer_type == 'input' ? (
                <Input type="number" placeholder="Please enter your response" />
              ) : (
                <Select
                  options={question.answers.map((opt) => ({
                    label: opt.display_name,
                    value: opt.id,
                  }))}
                  showSearch
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Please select"
                />
              )}
            </Form.Item>
            <Divider />
          </div>
        ))}
        <Button type="primary" htmlType="submit">Submit</Button>
      </Form>
      
      ) : (
        <div>No Questions Found</div>
      )}
    </div>
        </div>
      </div>
    </div>
    
  );
};

export default Prescreener;
